import React, { Component } from 'react'
import Link from 'next/link'
import ipdata from "../../actions/ipdata";
import { withRouter } from 'next/router'
import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF';
import { FaLinkedinIn } from '@react-icons/all-files/fa/FaLinkedinIn';
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter';
import Navigation from "../../config/navigation.json"
import ComponentConfig from '../../config/component.json';

class Footer extends Component {

    state = {
      ipdata: {},
      currentRoute: ''
    }

    componentDidMount() {
      this.state.currentRoute = window.location.pathname.split("/")[1];
      ipdata.getLocation().then((res) => {
        if(res){
          this.setState({ ipdata: JSON.parse(localStorage.getItem('user_location_details_local')).geoLocationDetails })
        }
      }).catch( err => console.log(err) );
    }

    linkNavigation = (route) => {
      if (route != this.state.currentRoute) {
        document.getElementById('nav').append(`<div class="loader">
                <div class="loader-inner">
                    <div class="book">
                        <div class="back"></div>
                        <div class="page5"></div>
                        <div class="page4"></div>
                        <div class="page3"></div>
                        <div class="page2"></div>
                        <div class="page1"></div>
                        <div class="front"></div>
                    </div>
                </div>
            </div>`);
      }
      this.props.router.push("/"+route);
    };

    render() {

      const IndiaFooter = () => {
        return (
          <div className="footer">
            <div className="footer-grid india">
              <div className="footer-branding text-white">
                <div className="logo mb-2">
                  <img src={process.env.NEXT_PUBLIC_FOOTER_LOGO} alt="Brand-Logo" width="105px" height="auto" />
                </div>
              </div>
              <div className="footer-links-1 text-white">
                {
                  Navigation?.BLOG &&
                  <Link href={process.env.NEXT_PUBLIC_STATIC_URI + 'blog'} prefetch={false}><a><h5 className="footer-link">Blog</h5></a></Link>
                }
                {
                  Navigation?.CAREER &&
                  <Link href={process.env.NEXT_PUBLIC_STATIC_URI + 'careers'} prefetch={false}><a><h5 className="footer-link">Careers</h5></a></Link>
                }
                {
                  Navigation?.TESTIMONIAL &&
                  <Link href={process.env.NEXT_PUBLIC_STATIC_URI + 'testimonials'} prefetch={false}><a><h5 className="footer-link">Testimonials</h5></a></Link>
                }
              </div>
              <div className="footer-links-2 text-white">
                {
                  Navigation?.CONTACT_US &&
                  <Link href={process.env.NEXT_PUBLIC_STATIC_URI + 'contact'} prefetch={false}><a><h5 className="footer-link">Contact Us</h5></a></Link>
                }
                {
                  ComponentConfig?.AGENT &&
                  <Link href='/signup?as=agent' prefetch={false}><a><h5 className="footer-link">Partner Signup</h5></a></Link>
                }
                {
                  ComponentConfig?.ORG_HEAD &&
                  <Link href='/signup?as=orgHead' prefetch={false}><a><h5 className="footer-link">Educator Signup</h5></a></Link>
                }
              </div>
              <div className="footer-links-3 text-white">
                {
                  Navigation?.REFUND_POLICY &&
                  <Link href={process.env.NEXT_PUBLIC_STATIC_URI + 'refund'} prefetch={false}><a><h5 className="footer-link">Refund Policy</h5></a></Link>
                }
                {
                  Navigation?.PRIVACY_POLICY &&
                  <Link href={process.env.NEXT_PUBLIC_STATIC_URI + 'privacy'} prefetch={false}><a><h5 className="footer-link">Privacy Policy</h5></a></Link>
                }
                {
                  Navigation?.TERMS_AND_CONDITION &&
                  <Link href={process.env.NEXT_PUBLIC_STATIC_URI + 'terms'} prefetch={false}><a><h5 className="footer-link">Terms &amp; Conditions</h5></a></Link>
                }
              </div>
              {
                Navigation?.SOCIAL_NETWORKS &&
                <div className="footer-links-social">
                  <h5 className="mb-3">Follow Us</h5>
                  <div className={`flex-row justify-content-start ${process.env.NEXT_PUBLIC_BRAND_NAME?.toLowerCase()}`}>
                    <a href="https://www.facebook.com/collegify" target="_blank" rel="noreferrer"><div className="footer-social-circle me-3"><FaFacebookF /></div></a>
                    <a href="https://www.twitter.com/collegify" target="_blank" rel="noreferrer"><div className="footer-social-circle me-3"><FaTwitter /></div></a>
                    <a href="https://www.linkedin.com/company/2692554/" target="_blank" rel="noreferrer"><div className="footer-social-circle"><FaLinkedinIn /></div></a>
                  </div>
                </div>
              }
            </div>

            <hr className="my-3" />

            <div className={`footer-copyright text-center ${process.env.NEXT_PUBLIC_BRAND_NAME?.toLowerCase()}`}>
              <h5>&copy; Copyright {new Date().getFullYear()} {process.env.NEXT_PUBLIC_COMPANY_NAME}</h5>
            </div>
          </div>
        )
      }

      // const InternationalFooter = () => {
      //   return (
      //     <div className="footer">
      //       <div className="footer-grid international">
      //         <div className="footer-branding text-white">
      //           <div className="logo mb-2">
      //             <img src={process.env.NEXT_PUBLIC_FOOTER_LOGO} alt="Brand-Logo" width="105px" height="auto" />
      //           </div>
      //         </div>
      //         <div className="footer-links-2 text-white">
      //           {
      //             Navigation?.STUDENT &&
      //             <Link href="/student"><a onClick={this.linkNavigation.bind(this,'student')}><h5 className="footer-link">Students</h5></a></Link>
      //           }
      //           {
      //             Navigation?.TEACHER &&
      //             <Link href="/teacher"><a onClick={this.linkNavigation.bind(this,'teacher')}><h5 className="footer-link">Teachers</h5></a></Link>
      //           }
      //           {
      //             Navigation?.PARENT &&
      //             <Link href="/parent"><a onClick={this.linkNavigation.bind(this,'parent')}><h5 className="footer-link">Parents</h5></a></Link>
      //           }
      //         </div>
      //         <div className="footer-links-2 text-white">
      //           {
      //             Navigation?.PRICING &&
      //             <Link href="/pricing"><a onClick={this.linkNavigation.bind(this,'pricing')}><h5 className="footer-link">Buy Now</h5></a></Link>
      //           }
      //           {
      //             Navigation?.TESTIMONIAL &&
      //             <Link href="/testimonials"><a onClick={this.linkNavigation.bind(this,'testimonials')}><h5 className="footer-link">Testimonials</h5></a></Link>
      //           }
      //           {
      //             Navigation?.CONTACT_US &&
      //             <Link href="/contact"><a onClick={this.linkNavigation.bind(this,'contact')}><h5 className="footer-link">Contact Us</h5></a></Link>
      //           }
      //         </div>
      //         <div className="footer-links-3 text-white">
      //           {
      //             Navigation?.TERMS_AND_CONDITION &&
      //             <Link href="/terms"><a onClick={this.linkNavigation.bind(this,'terms')}><h5 className="footer-link">Terms &amp; Conditions</h5></a></Link>
      //           }
      //           {
      //             Navigation?.REFUND_POLICY &&
      //             <Link href="/refund"><a onClick={this.linkNavigation.bind(this,'refund')}><h5 className="footer-link">Refund Policy</h5></a></Link>
      //           }
      //           {
      //             Navigation?.PRIVACY_POLICY &&
      //             <Link href="/privacy"><a onClick={this.linkNavigation.bind(this,'privacy')}><h5 className="footer-link">Privacy Policy</h5></a></Link>
      //           }
      //         </div>
      //       </div>
      //       <hr className="my-3" />
      //       <div className={`footer-copyright ${process.env.NEXT_PUBLIC_BRAND_NAME?.toLowerCase()} text-center`}>
      //         <h5>&copy; Copyright {new Date().getFullYear()} {process.env.NEXT_PUBLIC_COMPANY_NAME}</h5>
      //       </div>
      //     </div>
      //   )
      // }

      return (
        <React.Fragment>
          {
            Navigation?.FOOTER_QUICK_LINKS ?
            <>
              { this.state.ipdata.country_code === 'IN' ? (
                <IndiaFooter />
              ) : (
                <IndiaFooter />
              )}
            </>
              :
            <>
              <div className="footer">
                <div className="footer-copyright text-center">
                  <h5>&copy; Copyright {new Date().getFullYear()} {process.env.NEXT_PUBLIC_COMPANY_NAME}</h5>
                </div>
              </div>
            </>
          }
        </React.Fragment>
      )

    }
}
export default withRouter(Footer);