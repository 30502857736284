/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react'
import SideBar from './Sidebar'
import ipdata from "../../actions/ipdata";
import {MdSchool, MdArrowForward} from "react-icons/md";
import Link from 'next/link'
import { withRouter } from 'next/router'
import Navigation from "../../config/navigation.json"

class Navbar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ipdata: {},
      currentRoute: '',
      loading: true
    };
  }

  componentDidMount() {
    ipdata.getLocation().then((res) => {
      if (res) {
        this.setState({ ipdata: JSON.parse(localStorage.getItem('user_location_details_local')).geoLocationDetails, loading: false })
      }
    }).catch(err => {
      console.log(err.message)
      this.setState({ loading: false});
    });
    this.mobileNavFunctions();
  }

	mobileNavFunctions = () => {
	  const showNav = () => {
	    document.querySelector('.landing-mobile-nav').classList.add('show');
	    document.querySelector('.landing-mobile-header--overlay').classList.add('show');
	  };
	  const hideNav = () => {
	    document.querySelector('.landing-mobile-nav').classList.remove('show');
	    document.querySelector('.landing-mobile-header--overlay').classList.remove('show');
	  };
	  document.querySelector('.landing-mobile-header--icon').onclick = function () {
	    showNav();
	  };
	  document.querySelector('.landing-mobile-nav--close').onclick = function () {
	    hideNav();
	  };
	  document.querySelector('.landing-mobile-header--overlay').onclick = function () {
	    hideNav();
	  };
	  if(document.querySelector('.landing-mobile-nav--links li')){
		document.querySelector('.landing-mobile-nav--links li').onclick = function () {
			hideNav();
		};
	  }
	};

	linkNavigation = (route) => {
	  this.props.router.push(process.env.NEXT_PUBLIC_STATIC_URI + route);
	};

	render() {
	  const IndiaNav = (props) => {
	    return (
	      <ul className='navigation-links'>
	        {
				Navigation?.SAT &&
				<li><a href={process.env.NEXT_PUBLIC_STATIC_URI + 'course/sat'}>SAT</a></li>
	        }
	        {
				Navigation?.ACT &&
				<li><a href={process.env.NEXT_PUBLIC_STATIC_URI + 'course/act'}>ACT</a></li>
	        }
	        {
				Navigation?.MOCK_TEST &&
				<li><a href={process.env.NEXT_PUBLIC_STATIC_URI + 'mocktest'}>Mock Tests</a></li>
	        }
	        {
				Navigation?.ADMISSION &&
				<li className="nav-button">
				  <a href={process.env.NEXT_PUBLIC_ADMISSION_URI}>
				    <span className="d-flex align-items-center text-grey">
				      <MdSchool className="me-2 text-grey"/> Admissions
				    </span>
				  </a>
				</li>
	        }
			{
				!Navigation?.MORE && Navigation?.ONLINE_CLASS &&
				<li>
					<Link href={process.env.NEXT_PUBLIC_STATIC_URI + "online-classes"}><a>Online Classes</a></Link>
				</li>
			}
			{
				!Navigation?.MORE && Navigation?.BLOG &&
				<li>
					<Link href={process.env.NEXT_PUBLIC_STATIC_URI + "blog"}><a>Blog</a></Link>
				</li>
			}
	        {
				Navigation?.MORE &&
				<li className="dropdown d-flex">
				  <a id="dropdownMenuButton" className="pointer d-flex justify-content-start more-btn" data-bs-toggle="dropdown" aria-expanded="false">
				    <label className="mb-0 pointer">MORE</label>
				    <span className="badge bg-primary my-auto ms-2 rounded-0 fw-bold" style={{fontSize:10,padding: "5px 8px"}}>NEW</span>
				  </a>
				  <div className="dropdown-menu shadow-lg border-0 px-2 mt-2" aria-labelledby="dropdownMenuButton">
				    {
						Navigation?.EDUCATOR &&
						<Link href={process.env.NEXT_PUBLIC_STATIC_URI + "educators"}><a className="dropdown-item p-2 pl-2">Educators</a></Link>
				    }
				    {
						Navigation?.ONLINE_CLASS &&
						<Link href={process.env.NEXT_PUBLIC_STATIC_URI + "online-classes"}><a className="dropdown-item p-2 pl-2">Online Classes</a></Link>
				    }
				    {
						Navigation?.AI_TOOL &&
						<Link href={process.env.NEXT_PUBLIC_STATIC_URI + "essay"}><a className="dropdown-item p-2 pl-2">AI Essay Tool</a></Link>
				    }
				    {
						Navigation?.BLOG &&
						<Link href={process.env.NEXT_PUBLIC_STATIC_URI + "blog"}><a className="dropdown-item p-2 pl-2">Blog</a></Link>
				    }
				  </div>
				</li>
	        }
	      </ul>
	    )
	  };
	  const InternationalNav = (props) => {
	    return (
	      <ul className='navigation-links'>
	        {
				Navigation?.SAT &&
				<li><a href={process.env.NEXT_PUBLIC_STATIC_URI + 'course/sat'}>SAT</a></li>
	        }
	        {
				Navigation?.ACT &&
				<li><a href={process.env.NEXT_PUBLIC_STATIC_URI + 'course/act'}>ACT</a></li>
	        }
	        {
				Navigation?.MOCK_TEST &&
				<li><a href={process.env.NEXT_PUBLIC_STATIC_URI + 'mocktest'}>Mock Tests</a></li>
	        }
	        {
				Navigation?.EDUCATOR &&
				<li><a href={process.env.NEXT_PUBLIC_STATIC_URI + 'educators'}>Educators</a></li>
	        }
	        {
				Navigation?.PRICING &&
				<li><a href={process.env.NEXT_PUBLIC_STATIC_URI + 'pricing'}>Pricing</a></li>
	        }
	        {
				Navigation?.MORE &&
				<li className="dropdown d-flex">
				  <a id="dropdownMenuButton" className="pointer d-flex justify-content-start more-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
				    <label className="mb-0 pointer">MORE</label>
				    <span className="badge badge-primary my-auto ms-2 rounded-0 fw-bolder" style={{fontSize:10,padding: "5px 8px"}}>NEW</span>
				  </a>
				  <div className="dropdown-menu shadow-lg border-0 px-3 mt-2" aria-labelledby="dropdownMenuButton">
				    {
						Navigation?.AI_TOOL &&
						<Link href={process.env.NEXT_PUBLIC_STATIC_URI + "essay"}><a className="dropdown-item p-2 pl-2">AI Essay Tool</a></Link>
				    }
				    {
						Navigation?.BLOG &&
						<Link href={process.env.NEXT_PUBLIC_STATIC_URI + "blog"}><a className="dropdown-item p-2 pl-2">Blog</a></Link>
				    }
				  </div>
				</li>
	        }
	      </ul>
	    )
	  };
	  const Loading = () => {
	    return (
	      <ul className='navigation-links'>
	        {
	          [...Array(5)].map((val,i) => (
	            <li key={i}>
	              <div className="content-loading rounded" style={{width: 80, height: 30}}></div>
	            </li>
	          ))
	        }
	      </ul>
	    );
	  };
	  const RightSideLoader = () => {
	    return (
	      <ul className='navigation-links'>
	        {
	          [...Array(2)].map((val,i) => (
	            <li key={i}>
	              <div className="content-loading rounded" style={{width: 80, height: 30}}></div>
	            </li>
	          ))
	        }
	      </ul>
	    );
	  }
	  return (
	    <div>
	      <SideBar
	        ipdata={this.state.ipdata}
	        linkNavigation={this.linkNavigation.bind(this)}
	      />

	      <div className="navigation-wrapper flex-row align-items-center justify-content-between">
	        <div className="flex-row">
	          <div className={`logo-wrapper ${process.env.NEXT_PUBLIC_BRAND_NAME?.toLowerCase()}`}>
	            <Link href="/">
	              <img src={process.env.NEXT_PUBLIC_BRAND_LOGO} alt="Brand-Logo" width="auto" height="36px" />
	            </Link>
	          </div>
	          <div className="nav-topics ">
	            {this.state.loading ?
	              <Loading />
	              :
	              this.state.ipdata.country_code === 'IN' ?
	              <IndiaNav />
	              :
	              <InternationalNav />
	            }
	          </div>
	        </div>
	        <div className="nav-right-wrapper">
	          {
	            this.state.loading ?
	              <RightSideLoader />
	              :
	              <ul>
	                {
						Navigation?.SIGN_IN &&
						<li>
						  <a href="/">Sign In</a>
						</li>
	                }
	                {
						Navigation?.SIGN_UP &&
						<li className="nav-button">
						  <a href={process.env.NEXT_PUBLIC_STUDENT_APP_URI + '/signup'}>
						    <button className="btn btn-primary text-white rounded-pill">Sign up as student
						      <MdArrowForward className="ms-2" /></button>
						  </a>
						</li>
	                }
	          	</ul>
	          }
	        </div>
	      </div>
	    </div>
	  )
	}

}

export default withRouter(Navbar);