
const BASE_URL = `${process.env.NEXT_PUBLIC_API_URI}${process.env.NEXT_PUBLIC_API_VERSION}`;
const BASE_OPTIONS = {
    mode: 'cors',
    cache: 'no-cache',
    headers: {
        'content-type': 'application/json',
        apikey: process.env.NEXT_PUBLIC_CLG_API_KEY,
        'Access-Control-Allow-Origin': '*'
    },
    redirect: 'follow',
    // referrerPolicy: 'no-referrer',
}

const checkStatus = (response) => {
    if (response.status >= 200 && response.status < 300) {
        return response
    } else {
        var error = new Error(response.statusText)
        error.response = response
        throw error
    }
}

const parseJSON = (response) => {
    return response.json()
}

const get = (url, param, header) => {
    url = url.includes('http') ? url : `${BASE_URL}${url}${param ? encodeURIComponent(JSON.stringify(param)) : ''}`;
    const option = {
        ...BASE_OPTIONS,
        method: 'GET',
        headers: header ? {
            ...BASE_OPTIONS.headers,
            ...header
        } : {}
    };
    return fetch(url, option)
        .then(checkStatus)
        .then(parseJSON)
        .then((res) => res)
        .catch((err) => err);
};

const post = (url, data, header, formData = false, param = null) => {
    url = url.includes('http') ? url : `${BASE_URL}${url}${param ? encodeURIComponent(JSON.stringify(param)) : ''}`;
    const option = {
        ...BASE_OPTIONS,
        method: 'POST',
        headers: formData ? header : header ? {
            ...BASE_OPTIONS.headers,
            ...header
        } : {},
        body: formData ? data : JSON.stringify(data)
    };
    return fetch(url, option)
        .then(checkStatus)
        .then(parseJSON)
        .then((res) => res)
        .catch((err) => err);
};

const put = (url, data, header, formData = false, param = null) => {
    url = url.includes('http') ? url : `${BASE_URL}${url}${param ? encodeURIComponent(JSON.stringify(param)) : ''}`;
    const option = {
        ...BASE_OPTIONS,
        method: 'PUT',
        headers: header ? {
            ...BASE_OPTIONS.headers,
            ...header
        } : {},
        body: formData ? data : JSON.stringify(data)
    };
    return fetch(url, option)
        .then(checkStatus)
        .then(parseJSON)
        .then((res) => res)
        .catch((err) => err);
};

const patch = (url, data, header, formData = false, param = null) => {
    url = url.includes('http') ? url : `${BASE_URL}${url}${param ? encodeURIComponent(JSON.stringify(param)) : ''}`;
    const option = {
        ...BASE_OPTIONS,
        method: 'PATCH',
        headers: header ? {
            ...BASE_OPTIONS.headers,
            ...header
        } : {},
        body: formData ? data : JSON.stringify(data)
    };
    return fetch(url, option)
        .then(checkStatus)
        .then(parseJSON)
        .then((res) => res)
        .catch((err) => err);
};

const del = (url, data, header, param = null) => {
    url = url.includes('http') ? url : `${BASE_URL}${url}${param ? encodeURIComponent(JSON.stringify(param)) : ''}`;
    const option = {
        ...BASE_OPTIONS,
        method: 'DELETE',
        headers: header ? {
            ...BASE_OPTIONS.headers,
            ...header
        } : {},
        body: JSON.stringify(data)
    };
    return fetch(url, option)
        .then(checkStatus)
        .then(parseJSON)
        .then((res) => res)
        .catch((err) => err);
};



export { get, post, put, patch, del };
