import Cookie from "./cookie";
import { post } from './Fetch';

const SlackWebHook = {

    /**
     * send error log to slack
     * @author: Arijit Deb Roy
     * @params: file obj, mime type arr[], file size
     */

    sendSlackMessage(page, func, error, slackUrl = process.env.NEXT_PUBLIC_SLACK_URL, username = `${process.env.NEXT_PUBLIC_BRAND_NAME}-Partner`, channel = process.env.NEXT_PUBLIC_SLACK_CHANNEL, icon_emoji = ":scream:"){
        if(error.includes('403')) {
          Cookie.deleteCookie("_accessRole");
          Cookie.deleteCookie("_accessToken");
          window.location.replace("/");
        }
        let text = `${page}-${func}-${error}`;
        let message =  {text, channel, username, icon_emoji};

        if(slackUrl) {
        return post(`${slackUrl}`, message, null)
            .then(res => res.data)
            .catch(error => error);
        }
        else {
            console.log(message);
        }

    }

}

export default SlackWebHook;
