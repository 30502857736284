import { get } from './Fetch';

const Ipdata = {
    defaultLocation() {
        return (
            {"ip":"185.209.178.54","is_eu":false,"city":"New York","region":"New York","region_code":"NY","country_name":"United States","country_code":"US","continent_name":"North America","continent_code":"NA","latitude":40.7123,"longitude":-74.0068,"postal":"10118","calling_code":"1","flag":"https://ipdata.co/flags/us.png","emoji_flag":"🇺🇸","emoji_unicode":"U+1F1FA U+1F1F8","languages":[{"name":"English","native":"English"}],"currency":{"name":"US Dollar","code":"USD","symbol":"$","native":"$","plural":"US dollars"},"time_zone":{"name":"America/New_York","abbr":"EDT","offset":"-0400","is_dst":true,"current_time":"2021-06-29T07:31:31.426955-04:00"},"threat":{"is_tor":false,"is_proxy":false,"is_anonymous":false,"is_known_attacker":false,"is_known_abuser":true,"is_threat":true,"is_bogon":false},"count":"2"}
        )
    },
    getLocation() {
        return new Promise((resolve, reject) => {
            try {
                var geoApiCall = false;
                if (
                    JSON.parse(localStorage.getItem("user_location_details_local")) ==
                    null
                ) {
                    geoApiCall = true;
                } else if (
                    JSON.parse(localStorage.getItem("user_location_details_local"))
                        .timestamp == undefined
                ) {
                    geoApiCall = true;
                } else if (
                    Math.round(new Date().getTime() / 1000) >
                    Math.round(
                        JSON.parse(localStorage.getItem("user_location_details_local"))
                            .timestamp / 1000
                    ) +
                    24 * 3600
                ) {
                    geoApiCall = true;
                }
                if (geoApiCall) {
                    get(`https://api.ipdata.co?api-key=${process.env.NEXT_PUBLIC_LOCATION_API_KEY}`, null, null)
                        .then((res) => {
                            if(!(res instanceof Error)) {
                                localStorage.setItem(
                                    "user_location_details_local",
                                    JSON.stringify({
                                        geoLocationDetails: res,
                                        timestamp: new Date().getTime(),
                                    })
                                );
                                resolve(true);
                            } else {
                                localStorage.setItem(
                                    "user_location_details_local",
                                    JSON.stringify({
                                        geoLocationDetails: this.defaultLocation(),
                                        timestamp: new Date().getTime(),
                                    })
                                );
                                resolve(true);
                            }
                        })
                        .catch((error) => reject(error.message));
                } else {
                    resolve(true);
                }
            } catch (e) {
                reject(e.message);
            }
        });
    },

}

export default Ipdata;
