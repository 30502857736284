import React from 'react';
import Link from 'next/link'
import { MdMenu } from '@react-icons/all-files/md/MdMenu';
import { MdClose } from '@react-icons/all-files/md/MdClose';
import { MdSchool } from '@react-icons/all-files/md/MdSchool';
import Navigation from "../../config/navigation.json"

export default function Sidebar(props) {
    return (
        <div>
            <div className="landing-mobile-header navigation-wrapper">
                <div className="d-flex justify-content-start align-items-center h-100">
                    <div className="landing-mobile-header--icon pointer">
                        <MdMenu fontSize="24px" />
                    </div>
                    <div className={`logo-wrapper hrCenter my-2 ${process.env.NEXT_PUBLIC_BRAND_NAME?.toLowerCase()}`}>
                        <Link href="/">
                            <img src={process.env.NEXT_PUBLIC_BRAND_LOGO} alt="Brand-Logo" width="auto" height="36px" />
                        </Link>
                    </div>
                </div>
            </div>

            <div className="landing-mobile-header--overlay" />

            <div className="landing-mobile-nav">
                <div className="landing-mobile-nav--close pointer">
                    <MdClose fontSize="24px" />
                </div>
                <div className="landing-mobile-nav--links flex-column justify-content-start align-items-start">
                    {props.ipdata.country_code === 'IN' ?
                        <ul className='navigation-links'>
                            {
                Navigation?.SAT &&
                <li><a href={process.env.NEXT_PUBLIC_STATIC_URI + 'course/sat'}>SAT</a></li>
                            }
                            {
                Navigation?.ACT &&
                <li><a href={process.env.NEXT_PUBLIC_STATIC_URI + 'course/act'}>ACT</a></li>
                            }
                            {
                Navigation?.MOCK_TEST &&
                <li><a href={process.env.NEXT_PUBLIC_STATIC_URI + 'mocktest'}>Mock Tests</a></li>
                            }
                            {
                Navigation?.ADMISSION &&
                <li className="nav-button">
                    <a href={process.env.NEXT_PUBLIC_ADMISSION_URI}>
                        <span className="d-flex align-items-center text-secondary">
                            <MdSchool className="me-2 text-secondary"/> Admissions
                        </span>
                    </a>
                </li>
                            }
                            {
                Navigation?.MORE &&
                <li className="dropdown d-flex">
                    <a id="dropdownMenuButton-mobile" className="pointer d-flex justify-content-start more-btn" data-bs-toggle="dropdown">
                        <label className="mb-0 pointer">MORE</label>
                        <span className="badge badge-primary my-auto ms-2 rounded-0 fw-bolder" style={{fontSize:10,padding: "5px 8px"}}>NEW</span>
                    </a>
                    <div className="dropdown-menu shadow-lg border-0 px-3 mt-2" aria-labelledby="dropdownMenuButton-mobile">
                        {
                      Navigation?.EDUCATOR &&
                      <Link href={process.env.NEXT_PUBLIC_STATIC_URI + "educators"}><a className="dropdown-item p-2 pl-2">Educators</a></Link>
                        }
                        {
                      Navigation?.ONLINE_CLASS &&
                      <Link href={process.env.NEXT_PUBLIC_STATIC_URI + "online-classes"}><a className="dropdown-item p-2 pl-2">Online Classes</a></Link>
                        }
                        {
                      Navigation?.AI_TOOL &&
                      <Link href={process.env.NEXT_PUBLIC_STATIC_URI + "essay"}><a className="dropdown-item p-2 pl-2">AI Essay Tool</a></Link>
                        }
                        {
                      Navigation?.BLOG &&
                      <Link href={process.env.NEXT_PUBLIC_STATIC_URI + "blog"}><a className="dropdown-item p-2 pl-2">Blog</a></Link>
                        }
                    </div>
                </li>
                            }
                        </ul>
                        :
                        (
                            <ul className='navigation-links'>
                                {
                  Navigation?.SAT &&
                  <li><a href={process.env.NEXT_PUBLIC_STATIC_URI + 'course/sat'}>SAT</a></li>
                                }
                                {
                  Navigation?.ACT &&
                  <li><a href={process.env.NEXT_PUBLIC_STATIC_URI + 'course/act'}>ACT</a></li>
                                }
                                {
                  Navigation?.MOCK_TEST &&
                  <li><a href={process.env.NEXT_PUBLIC_STATIC_URI + 'mocktest'}>Mock Tests</a></li>
                                }
                                {
                  Navigation?.EDUCATOR &&
                  <li><a href={process.env.NEXT_PUBLIC_STATIC_URI + 'educators'}>Educators</a></li>
                                }
                                {
                  Navigation?.PRICING &&
                  <li><a href={process.env.NEXT_PUBLIC_STATIC_URI + 'pricing'}>Pricing</a></li>
                                }
                                {
                  Navigation?.BLOG &&
                  <li><a href={process.env.NEXT_PUBLIC_STATIC_URI + 'blog'}>Blog</a></li>
                                }
                            </ul>
                        )
                    }
                    <div className="landing-mobile-nav--links_bottom">
                        {
              Navigation?.SIGN_IN &&
              <a href={"/"}>
                  <button className="btn btn-lg btn-primary rounded-pill mb-4">Sign In</button>
              </a>
                        }
                        {
              Navigation?.SIGN_UP &&
              <a href={process.env.NEXT_PUBLIC_STUDENT_APP_URI + '/signup'}>
                  <button className="btn btn-lg btn-primary rounded-pill">Sign up as student</button>
              </a>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
