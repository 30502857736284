import Head from "next/head";
import React, { Component } from "react";
import NavBar from '../components/Navigation/Navbar';
import Footer from '../components/Footer/Footer';
import SlackWebHook from '../actions/slack';

export default class Layout extends Component {

  componentDidCatch(error, info) {
    SlackWebHook.sendSlackMessage('SiteLayout','componentDidCatch',`${error.message}-${info}`);
  }

  render() {
    return (
      <div>
        <Head>
          <meta charSet="utf-8"/>
          <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
          <meta httpEquiv="Pragma" content="no-cache"/>
          <meta httpEquiv="Expires" content="-1"/>
          <meta name="viewport" content="initial-scale=1.0, width=device-width" />
          <meta name="theme-color" content="#FFD200"/>
          <meta property="og:description" content="New Partners Platform"/>


          <title>{process.env.NEXT_PUBLIC_BRAND_NAME} Partners</title>
        </Head>
        <main className="overflow-x-hidden layout">
          <NavBar/>
          {this.props.children}
          <Footer />
        </main>
      </div>
    )
  }
}
